<script>
import formVal from '@/plugins/form-validator';
import OemApiTable from '@/components/OemApiTable.vue';

export default {
  name: 'OEMLoggerPage',
  components: {
    OemApiTable,
  },
  watch: {
    /**
     * Changes when a logger is selected from a search box. Will pull that user's
     *   info from the server
     */
    selected_logger() {
      if (this.selected_logger) {
        this.logger.loading = true;
        this.$http({
          url: `/oem/logger/${this.selected_logger.id}`,
          method: 'GET',
        })
          .then((resp) => {
            this.logger.serial = resp.data.serial;
            this.logger.name = resp.data.name || '';
            this.logger.description = resp.data.description || '';
            return this.$http({
              url: `/oem/logger/${this.logger.serial}/access_key`,
              method: 'GET',
            });
          })
          .then((resp) => {
            this.logger.access_key = resp.data.access_key;
            this.logger.loading = false;
          });
      } else {
        this.logger.serial = '';
        this.logger.name = '';
        this.logger.description = '';
        this.logger.access_key = '';
      }
    },
  },
  data() {
    return {
      selected_logger: null,
      fields: [
        {
          key: 'serial',
          label: 'Serial',
        },
        {
          key: 'name',
          label: 'Name',
          formatter: name => name || '(Not Set)',
        },
        {
          key: 'description',
          label: 'Description',
          formatter: description => description || '(Not Set)',
        },
        {
          key: 'owner',
          label: 'Owner',
        },
        {
          key: 'id',
          label: '',
        },
      ],
      logger: {
        loading: true,
        id: '',
        serial: '',
        name: '',
        description: '',
      },
      provision_logger: {
        serial: '',
        error: null,
      },
    };
  },
  methods: {
    /**
     * Called when a logger is selected for editing
     */
    editLogger() {
      this.$router.push(`/oem/logger/${this.selected_logger.id}`);
    },
    /**
     * Provision a new logger on the system
     */
    provisionLogger(evt) {
      evt.preventDefault();
      // Check the serial number is valid
      const valErr = formVal.validate.single(this.provision_logger.serial, { loggerSerial: true });
      if (valErr) {
        this.provision_logger.error = 'Invalid serial number format';
        return;
      }
      // Make the provisioning request
      this.$http({
        url: `/oem/logger/${this.provision_logger.serial}`,
        method: 'POST',
      })
        .then((resp) => {
          this.selected_logger = resp.data.id;
          this.$refs.provisionModal.hide();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.provision_logger.error = 'Invalid serial number format';
          } else if (err.response.status === 409) {
            this.provision_logger.error = 'Logger already exists on system';
          }
        });
    },
  },
};
</script>

<template>
  <div>

    <!-- Normally Visible Stuff -->
    <b-container>
      <b-row>
        <b-col cols=12>
          <b-card title='Logger Management' class='mb-2'>
            <b-container>
              <b-row>
                <!-- Table -->
                <oem-api-table action='admin/getLoggers'
                               :fields='fields'
                               addModal='admin-add-logger'
                               v-model='selected_logger'
                               v-on:input='editLogger'
                               ref='table'>
                </oem-api-table>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id='admin-edit-logger' title='Edit Logger' size='lg'>
      <b-container>
        <!-- Logger Management Starts Here -->
        <b-row class='mb-4'>
          <b-col cols=12>
            <b-card title='Logger Management'>
              <b-container>
                <!-- Logger Search Result -->
                <b-row class='mt-2' >
                  <b-col>
                    <b-form>
                      <b-form-group
                        label='Name'
                        >
                        <b-input disabled type='text' v-model="logger.name"/>
                      </b-form-group>
                      <b-form-group
                        label='Description'
                        >
                        <b-input disabled type='text' v-model="logger.description"/>
                      </b-form-group>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row align-h='center' class='mt-3'>
                  <b-col cols='auto'>
                    <b-btn variant='success'
                           v-bind:disabled='!selected_logger'
                           v-bind:to="`/logger/${this.selected_logger.id}`"
                           v-if='this.selected_logger'>
                      Go to Logger Page <fa-icon icon='arrow-right'></fa-icon>
                    </b-btn>
                  </b-col>
                </b-row>
                <b-row align-h='center' class='mt-4'>
                  <b-col cols='12'>

                    <!-- Danger Controls below Here -->
                    <b-card header='Danger Zone'
                            border-variant='danger'
                            header-border-variant='danger'
                            header-text-variant='danger'
                            align='center' >
                      <b-container>
                        <!-- Access Key Regeneration -->
                        <b-row class='mt-1'>
                          <b-col cols='12'>
                            <p class='lead'>Regenerate Access Key</p>
                            <b-input-group prepend='Access Key:'>
                              <b-form-input disabled v-model='this.logger.access_key' />
                              <b-input-group-append>
                                <b-btn variant='secondary'
                                      v-bind:disabled='!selected_logger'
                                      @click='this.$copyText(this.logger.acces_code)'>
                                  <fa-icon icon='copy'></fa-icon>
                                </b-btn>
                                <b-btn variant='danger' v-bind:disabled='!selected_logger'>
                                  Regenerate
                                </b-btn>
                              </b-input-group-append>
                            </b-input-group>
                            <p class='text-danger'>
                              Regenerating an access key will remove
                              the current owners and accessors from the logger.
                            </p>
                          </b-col>
                        </b-row>
                        <!-- Logger Token Regeneration -->
                        <b-row class='mt-3' align-v='start'>
                          <b-col cols=12 md=6>
                            <b-row>
                              <b-col>
                                <b-row>
                                <b-col>
                                  <p class='lead'>Regenerate and Download Logger Token</p>
                                </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols='12'>
                                    <b-btn variant='danger' v-bind:disabled='!selected_logger'>
                                      Revoke and Regenerate Logger Token
                                    </b-btn>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col><p class='text-danger'>
                                    This will prevent the logger from accessing PMGateway.
                                    Use only as a reprovisioning tool.
                                  </p></b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- Delete Logger -->
                          <b-col cols=12 md=6>
                            <b-row class='mt-3'>
                              <b-col cols='12'>
                                <p class='lead'>Delete Logger from System</p>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols='12'>
                                <b-btn variant='danger' v-bind:disabled='!selected_logger'>
                                  Delete Logger and All Files from System
                                </b-btn>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>

                      </b-container>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Logger Provisioning Dialog -->
    <b-modal id='admin-add-logger'
             title='Provision a New Logger'
             ref='provisionModal'
             @ok='provisionLogger'>
      Start the provisioning process by entering the serial number of the logger below.
      <b-form>
        <b-form-group label='Serial Number'>
          <b-input type='text'
                   v-model='provision_logger.serial'
                   v-bind:state='provision_logger.error === null ? null : false' />
          <b-form-invalid-feedback>{{ provision_logger.error }}</b-form-invalid-feedback>
          <b-form-text>Serial Number in the form PMXSXXXX</b-form-text>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>
